.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 27px;
	min-height: 27px;
}

.switch-disabled {
	opacity: 0.5;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider1 {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider1:before {
	position: absolute;
	content: '';
	height: 16px;
	width: 16px;
	left: 4px;
	bottom: 6px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider1 {
	background-color: #3c7a36;
}

input:focus + .slider1 {
	box-shadow: 0 0 1px #3c7a36;
}

input:checked + .slider1:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider1.round {
	border-radius: 34px;
}

.slider1.round:before {
	border-radius: 50%;
}
