.wifiBatteryContainer {
	width: 155px;
	height: 36px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 4px 0 4px;
	.removeIconWrapper {
		cursor: pointer;
		width: 30px;

		opacity: 0.8;
		&:hover {
			opacity: 1;
		}
	}
	.wifiIconWrapper {
		display: flex;
		flex-direction: row;
		justify-items: flex-end;
		width: fit-content;
		margin-left: auto;
		margin-right: 10px;
		height: 25px;
		cursor: pointer;
	}
	.wifiTooltip {
		position: absolute;
		z-index: 4;
		display: flex;
		padding: 8px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: fit-content;
		right: -55px;
		bottom: 150%;
		background: #242525;
		border-radius: 5px;
		div {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: #ffffff;
		}

		.wifiTooltipArrow {
			position: absolute;
			bottom: -10px;
			right: 63px;
			transform: rotate(180deg);
			border-width: 5px;
			border-style: solid;
			border-color: transparent transparent #242525 transparent;
		}

		.tooltipSection {
			display: inline-flex;
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
			height: 100%;
			margin-bottom: 5px;
		}

		.columnSpacer {
			width: 7px;
		}
		.endTextWrapper {
			width: fit-content;
			white-space: nowrap;
			display: flex;
			flex-direction: row;
			color: #8c8d8d;
		}
		.leftColumn {
			display: flex;
			flex-direction: row;
			justify-content: right;
			align-items: center;
			width: fit-content;
			flex-grow: 1;
			margin-right: var(--column-margin);
			white-space: nowrap;
			right: 0;
		}

		.rightColumn {
			display: flex;
			flex-direction: row;
			justify-content: left;
			align-items: center;
			width: 250px;
			margin-left: var(--column-margin);
			transition: background-color 1s ease;
			background-color: transparent;

			&.flash {
				background-color: #56ae4d;
			}
		}

		.numberHighlight {
			color: #ffffff;
			margin-right: 3px;
		}

		.sparklineContainer {
			flex-shrink: 0;
			width: 130px;
			height: 100%;
			margin-right: 3px;
		}
	}
	.wifiIcon {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		height: inherit;
	}
	.wifiIconImg {
		height: inherit;
	}
	.wifiIconId {
		position: absolute;
		bottom: 0;
		right: 0;
		font-family: 'Roboto';
		color: #ffffff;
		font-style: normal;
		font-weight: 900;
		font-size: 10px;
		line-height: 12px;
		background-color: #1e1f22;
	}
	.wifiIconIdHl {
		@extend .wifiIconId;
		color: #56ae4d;
	}
	.white-font {
		color: white;
	}
	.green-font {
		color: #56ae4d;
	}
	.batteryIconContainer {
		margin-right: 8px;
	}
	.batteryIcon {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		height: inherit;
	}
	.batteryTooltip {
		position: absolute;
		z-index: 4;
		display: flex;
		padding: 8px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 40px;
		bottom: 30px;
		background: #242525;
		border-radius: 5px;
		span {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: #ffffff;
		}

		.batteryTooltipArrow {
			position: absolute;
			bottom: -10px;
			transform: rotate(180deg);
			border-width: 5px;
			border-style: solid;
			border-color: transparent transparent #242525 transparent;
		}
	}
	.batteryStatusWrapper {
		display: none;
		position: absolute;
		bottom: 245px;
		right: -20px;
	}
	.batteryIconContainer:hover ~ .batteryStatusWrapper {
		display: block;
	}
	.wifiStatusWrapper {
		display: none;
		position: absolute;
		bottom: 245px;
		right: 10px;
	}
	.wifiIconWrapper:hover ~ .wifiStatusWrapper {
		display: block;
	}
}

.miniWifiBatteryContainer {
	padding: 0 12px 0 12px;
	.removeIconWrapper {
		cursor: pointer;
		width: 20px;
		margin-left: 8px;
		padding-top: 3px;

		img {
			width: 100%;
		}
	}
	.batteryIconContainer {
		margin-right: 0px;
	}
	.batteryStatusWrapper {
		bottom: 55px;
		right: -10px;
	}
	.wifiStatusWrapper {
		bottom: 55px;
		right: 40px;
	}
	.wifiIconWrapper {
		margin-left: 0;
		border-left: 1px solid white;
		padding-left: 13px;
		padding-right: 13px;
		border-right: 1px solid white;
		height: 25px;
	}
}

.miniLocalContainer {
	height: 50px !important;
	border-radius: 30px !important;
	padding: 0 8px !important;
	.wifiIconWrapper {
		margin-right: 0;
	}
	.wifiTooltip {
		bottom: 180%;
	}
}

.divider {
	height: 1px;
	background: #8c8d8d;
	width: 100%;
	margin-top: 5px;
	margin-bottom: 5px;
}
