.settingsContainer {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 500;
	color: #d2d2d3;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	animation: fadeIn 0.3s;

	.setting-msg-container {
		display: flex;
		flex-direction: row;
		align-items: center;

		.setting-msg-container-text {
			margin-left: 5px;
		}

		.setting-msg-container-disabled-text {
			color: red;
			margin-left: 5px;
		}
	}

	.settings {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		background-color: #1e1f22;
		opacity: 1;
		width: 90%;
		height: 90%;

		.settingsMenuContainer {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			overflow: auto;
			overflow-x: hidden;
			width: 20%;
			height: 100%;
			background-color: #353538;
			border-radius: 8px 0 0 8px;

			.settingsMenuContext {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				margin-top: 50px;

				.settingsMenuHeader {
					margin-bottom: 50px;
				}

				.settingsMenu {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: inherit;
					.pageSection {
						display: flex;
						flex-direction: column;
						align-items: center;
						gap: 20px;
						margin-bottom: 50px;
						width: 50%;
						.pageSectionPages {
							display: flex;
							flex-direction: column;
							align-items: center;
							gap: 10px;
							width: 100%;
						}
					}
				}
			}

			.closeButton {
				@extend .settingsHandleButton;
				width: inherit !important;
				margin: 50px;
			}

			/*
			*  Scroll bar styling
			*/
			&::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
				border-radius: 10px;
			}
			&::-webkit-scrollbar {
				width: 10px;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				background-color: #3c7a36;
				cursor: pointer;
			}
		}

		.settingsContext {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			width: 80%;
			height: 100%;
			background-color: #3c3e44;
			border-radius: 0 8px 8px 0;

			.tabSection {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				gap: 20px;
				margin-block: 30px;
			}

			.tabContext {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				gap: 100px;
				width: 95%;
				height: 90%;
				margin-bottom: 50px;
				overflow: auto;

				.settingSection {
					display: flex;
					flex-direction: column;
					gap: 30px;
					margin-inline: 50px;
					.settingsWrapper {
						display: flex;
						flex-direction: column;
						gap: 20px;
						width: 100%;
						height: 100%;
						.setting {
							display: flex;
							flex-direction: column;
							width: 100%;
							height: 100%;
							gap: 20px;
							.settingContainer {
								max-height: 40vh;
								.innerSetting {
									display: flex;
									flex-direction: column;
									width: 100%;
									height: 100%;
									gap: 10px;
								}
								.innerSettingDisabled {
									pointer-events: none;
									opacity: 0.5;
								}
							}
						}
					}
				}
			}

			/*
			*  Scroll bar styling
			*/
			.tabContext::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
				border-radius: 10px;
			}
			.tabContext::-webkit-scrollbar {
				width: 10px;
			}
			.tabContext::-webkit-scrollbar-thumb {
				border-radius: 10px;
				background-color: #3c7a36;
				cursor: pointer;
			}
		}

		.settingSectionHeader {
			font-weight: 1000;
			font-size: 15px;
			color: #78797a;
		}
		.settingSectionHeaderSidebar {
			font-weight: 1000;
			font-size: 15px;
			color: #78797a;
			text-align: center;
		}

		.settingHeader {
			@extend .settingSectionHeader;
			font-size: 14px;
		}

		.settingsHandleButton {
			display: flex;
			justify-content: center;
			text-align: center;
			padding: 5px;
			cursor: pointer;
			border-radius: 4px;
			font-weight: bolder;
			font-size: 14px;
			width: 100%;
			&:hover {
				background-color: #4e4e50;
			}
		}
		.settingsHandleButtonSelected {
			@extend .settingsHandleButton;
			background-color: #626264;
			cursor: unset;
			&:hover {
				background-color: #626264;
			}
		}

		.settingsButton {
			display: flex;
			justify-content: center;
			padding-block: 5px;
			padding-inline: 15px;
			border-radius: 4px;
			font-weight: bolder;
			font-size: 14px;
			background-color: #3c7a36;
			width: fit-content;
			cursor: pointer;
			&:hover {
				background-color: #626264;
			}
		}
	}
}
